import React from "react"
import { graphql } from "gatsby"
import PageRoot from "../components/PageRoot"
import AuthorHeader from "../components/AuthorHeader"
import ContainerWithPadding from "../components/ContainerWithPadding"
import AtsGrid from "../components/AtsGrid"
import { getImageUrl, renderCarouselItem } from "../utils"
import { useTranslation } from "react-i18next"
import { DEFAULT_PORTRAIT_IMAGE_SIZE } from "../constants/imageSizes"

const tileColSettings = {
  xs: 24,
  sm: 24,
  md: 8,
  lg: 6,
  xl: 6,
  xxl: 4,
}

const AuthorPage = ({ data }) => {
  const { t } = useTranslation()
  const {
    videos,
    on_demand_coruses,
    subscription_courses,
    authors_by_pk: author,
  } = data.ats

  const {
    firstname,
    lastname,
    biography,
    video_rels,
    short_biography,
    picture,
    seo,
  } = author

  const { title, description } = seo

  const seoImage = getImageUrl(picture, DEFAULT_PORTRAIT_IMAGE_SIZE)

  return (
    <PageRoot
      title={title}
      description={description}
      image={process.env.GATSBY_AUTH0_REDIRECT_URI + seoImage}
    >
      <AuthorHeader data={author} />
      <div className="author-video-wrapper">
        <ContainerWithPadding size={"large"}>
          {subscription_courses.length > 0 && (
            <div className="card-grid-wrapper">
              <h2>
                {t("label:authorSubscriptionCourses")}{" "}
                {`${firstname} ${lastname}`}
              </h2>
              <hr />
              <AtsGrid
                gutter={26}
                colSettings={tileColSettings}
                dataSource={subscription_courses}
                renderItem={renderCarouselItem}
              />
            </div>
          )}

          {on_demand_coruses.length > 0 && (
            <div className="card-grid-wrapper">
              <h2>
                {t("label:authorOnDemandCourses")} {`${firstname} ${lastname}`}
              </h2>
              <hr />
              <AtsGrid
                gutter={26}
                colSettings={tileColSettings}
                dataSource={on_demand_coruses}
                renderItem={renderCarouselItem}
              />
            </div>
          )}

          {videos.length > 0 && (
            <div className="card-grid-wrapper">
              <h2>
                {t("label:videoOf")} {`${firstname} ${lastname}`}
              </h2>
              <hr />
              <AtsGrid
                gutter={26}
                colSettings={tileColSettings}
                dataSource={videos}
                renderItem={renderCarouselItem}
              />
            </div>
          )}
        </ContainerWithPadding>
      </div>
    </PageRoot>
  )
}

export const query = graphql`
  query authorData($authorId: ats_uuid!) {
    ats {
      authors_by_pk(id: $authorId) {
        ...PreviewCardAuthor
        short_biography
        biography
        seo {
          title
          description
          slug
        }
        wallpaper {
          ...WallpaperAuthor
        }
      }
      videos(
        where: {
          _not: { course_rel: {} }
          _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
          author_rels: { author_id: { _eq: $authorId } }
        }
        order_by: { created_at: desc }
      ) {
        ...PreviewCardVideo
      }
      on_demand_coruses: courses(
        where: {
          state: { _eq: PUBLISHED }
          price_policy: { _eq: ON_DEMAND }
          videos: { video: { author_rels: { author_id: { _eq: $authorId } } } }
        }
        order_by: { created_at: desc }
      ) {
        ...PreviewCardCourse
      }
      subscription_courses: courses(
        where: {
          state: { _eq: PUBLISHED }
          videos: { video: { author_rels: { author_id: { _eq: $authorId } } } }
          _not: { price_policy: { _eq: ON_DEMAND } }
        }
        order_by: { created_at: desc }
      ) {
        ...PreviewCardCourse
      }
    }
  }
`

export default AuthorPage
